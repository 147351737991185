import Vue from 'vue'
import App from './App.vue'
import moment from "moment";
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import Axios from 'axios'
import router from "@/router";
import store from "@/store";

Vue.prototype.$http = Axios;
const token = "Bearer " + localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.filter('formatDateTime', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY hh:mm:ss')
  }
})

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
})

Vue.filter('formatTime', function(value) {
  if (value) {
    return moment(String(value)).format('HH:mm:ss')
  }
})

Vue.filter('roundDecimal', function(value) {
  if (value) {
    return value.toFixed(2)
  }
})