<template>
  <div>
   <form class="login" @submit.prevent="login">
     <h1>Inloggen</h1>
     <div v-if="invalid" class="alert alert-warning" role="alert">
      Gebruikersnaam of wachtwoord is ongeldig.
      </div>
     <div class="form-group">
     <label for="username">Gebruikersnaam</label>
     <input class="form-control"  id="username" required v-model="username" type="text" placeholder="voornaam.familienaam"/>
     </div>
     <div class="form-group">
     <label for="password">Wachtwoord</label>
     <input class="form-control"  id="password" required v-model="password" type="password" placeholder=""/>
     </div>

     <button type="submit"  class="btn btn-primary">Login</button>
   </form>
  </div>
</template>

<script>
export default {
name: "Login",
  data(){
      return {
        username : "",
        password : "",
        invalid: false
      }
    },
  methods: {
      login: async function () {
        this.invalid = false
        let username = this.username
        let password = this.password
        await this.$store.dispatch('login', {username, password})
            .then(() => this.$router.push('/'))
            .catch(err => function () {
              console.log(this.$store.getters.authStatus)
              console.log(err);
              this.invalid = true;
            })
        if (this.$store.getters.authStatus == "error"){
          this.invalid = true
        } else {
          this.invalid = false
        }
      }
    }
}
</script>

<style scoped>

</style>