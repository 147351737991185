<template>
  <div class="card">
    <div class="card-header bg-dark text-white">
      Wegingen
    </div>
    <div class="card-body">
      <form class="row mb-4" v-on:submit.prevent="getWegingen(false)">
        <div class="col-lg-3 col-md-6 col-xl-2">
          <label for="filter_datetime_from">Van:</label>
          <input class="form-control" type="datetime-local" id="filter_datetime_from" v-model="filter_datetime_from"/>
        </div>
        <div class="col-lg-3 col-md-6 col-xl-2">
          <label for="filter_datetime_to">Tot:</label>
          <input class="form-control" type="datetime-local" id="filter_datetime_to" v-model="filter_datetime_to"/>
        </div>
        <div class="col-xl-2 col-md-6 col-12">
          <label for="filter_hoknr">Hoknummer:</label>
          <input class="form-control" type="text" id="filter_hoknr" v-model="filter_hoknr"/>
        </div>
        <div class="col-xl-3 col-md-6 col-12">

          <label for="filter_user">Gebruiker</label>
          <select class="form-control" id="filter_user" v-model="filter_username">
            <option v-for="(user) in users" v-bind:key="user.username">
              {{ user.username }}
            </option>
          </select>

        </div>
        <div class="sol-12 col-lg-3">
          <label>Acties:</label>
          <div class="form-control p-0 border-0 ">
            <button class=" btn btn-primary mr-2"><i class="fas fa-sync-alt"></i></button>
            <div v-on:click="clearFilters" class=" btn btn-primary mr-2"><i class="fas fa-trash-alt"></i></div>
            <div v-on:click="getWegingen(true)" class=" btn btn-primary mr-2"><i class="fas fa-file-excel"></i></div>
            <div v-if="false" v-on:click="getWegingen(true)" class=" btn btn-primary mr-2"><i
                class="fas fa-file-pdf"></i></div>
          </div>
        </div>
      </form>
      <div class="cont-table">
        <table class="table">
          <thead>
          <tr>
            <th>Gebruiker</th>
            <th>Datum</th>
            <th>Tijd</th>
            <th>Hoknr</th>
            <th>Geslacht</th>
            <th>Aantal</th>
            <th>Gewicht</th>
            <th>Gemiddeld<br>gewicht</th>
            <th>Controle</th>
            <th>Opmerking</th>
            <th></th>

          </tr>
          </thead>
          <tbody>
          <tr v-for="(weging,index) in wegingen" v-bind:key="index">

            <td>{{ weging.user }}</td>
            <td>{{ weging.datetime | formatDate }}</td>
            <td>{{ weging.datetime | formatTime }}</td>
            <td>{{ weging.hoknr }}</td>
            <td>{{ weging.geslacht }}</td>
            <td>{{ weging.aantal }}</td>
            <td>{{ weging.gewicht }}</td>
            <td v-if="weging.gewicht!=null">{{ (weging.gewicht / weging.aantal) | roundDecimal }}</td>
            <td v-else></td>
            <td>{{ weging.controle }}</td>
            <td>{{ weging.opmerking }}</td>
            <td>
              <div class="btn btn-danger delete" v-on:click="deleteMeting(weging.id)"><i class="fas fa-trash-alt"></i>

              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  name: "WegingTable",
  components: {},
  data() {
    return {
      users: [],
      wegingen: [],
      filter_datetime_from: null,
      filter_datetime_to: null,
      filter_hoknr: null,
      filter_username: null,
    }
  },
  mounted() {
    this.getUsers()
    this.getWegingen(false)
  },
  methods: {
    deleteMeting: async function (event) {
       if(confirm("Verwijderen?")) {
         if (event) {
           await axios.delete('/api/weging/' + event,)
           this.getWegingen()
         }
       }

    },
    getUsers: function () {
      axios.get('/api/login',)
          .then(function (response) {
            this.users = response.data;
          }.bind(this));
    },
    getWegingen: function (excel) {
      if (this.filter_hoknr == "") {
        this.filter_hoknr = null
      }
      let params = {
        datetime__gte: this.filter_datetime_from,
        datetime__lte: this.filter_datetime_to,
        user: this.filter_username,
        hoknr: this.filter_hoknr
      }

      if (excel) {
        params["xlsx"] = "true";
        axios({
          method: 'GET',
          url: '/api/weging',
          responseType: 'blob',
          params: params
        }).then((response) => {
          const url = URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'}))
          const link = document.createElement('a')
          link.href = url
          document.body.appendChild(link)
          link.click()

        })
        this.getWegingen(false)
      } else {
        axios.get('/api/weging', {
          params: params

        })
            .then(function (response) {
              this.wegingen = response.data;
            }.bind(this));
      }
    },
    clearFilters: function () {
      this.filter_hoknr = null
      this.filter_datetime_from = null
      this.filter_datetime_to = null
      this.filter_username = null
      this.getWegingen()
    }
  }

}

</script>

<style scoped>
.cont-table {
  overflow-x: scroll;
}
</style>