<template>
<div class="card">
  <div class="card-header bg-dark text-white">
    Bascule
  </div>
  <div class="card-body">
    <h2 class="col-xs-6 mb-4"><span id="gewicht">{{bascule.gewicht}}</span> kg</h2>

    <WegingInput/>
  </div>
</div>
</template>

<script>
import axios from 'axios';
import WegingInput from "@/components/WegingInput";

export default {
  name: "Bascule",
  components: {
    WegingInput
  },
  data(){
        return {
            bascule: []
        }
    },
  mounted() {
    setInterval(async function () {
      await axios.get('/api/bascule',)
          .then(function (response) {
            this.bascule = response.data;
          }.bind(this));
    }.bind(this),1000)
    }

}
</script>

<style scoped>
  .card {
    width: 100%
  }
</style>