<template>
  <div class="card">
    <div class="card-header bg-dark text-white">
      Weging opslaan
    </div>
    <div class="card-body">
      <form v-on:submit.prevent="submitForm" class="row">
        <div class="form-group col-md-6">
          <label for="input_hoknummer">Hoknummer</label>
          <input type="text" class="form-control" id="input_hoknummer" v-model="form.hoknr">
        </div>

        <div class="form-group col-md-6">
          <label for="input_aantal">Aantal</label>

          <input type="number" class="form-control" id="input_aantal" v-model="form.aantal">

        </div>

        <div class="form-group col-md-6">
          <label for="input_geslacht">Geslacht</label>
          <select class="form-control" id="input_geslacht" v-model="form.geslacht">
            <option></option>
            <option>B</option>
            <option>Z</option>
            <option>B & Z</option>
          </select>
        </div>

        <div class="form-group col-md-6">
          <label for="input_controle">Controle/proef</label>
          <select class="form-control" id="input_controle" v-model="form.controle">
            <option></option>
            <option>C</option>
            <option>P</option>
          </select>
        </div>

        <div class="form-group col-md-6">
          <label for="input_opmerking">Opmerking</label>

          <input type="text" class="form-control" id="input_opmerking" v-model="form.opmerking">

        </div>
        <div class="form-group col-md-6">
          <label for="input_gewicht">Gewicht</label>

          <input required disabled type="number" class="form-control" id="input_gewicht" v-model="form.gewicht">

        </div>
        <div class="col-12">
          <div v-on:click="setgewicht" class="btn btn-primary mr-2">Gewicht instellen</div>
          <button v-if="saveBtn==true" class="btn btn-primary">Opslaan</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'WegingInput',
  data() {
    return {
      form: {
        hoknr: null,
        aantal: null,
        geslacht: null,
        controle: null,
        opmerking: null,
        gewicht: null
      },
      saveBtn: false
    }
  },
  methods: {
    submitForm() {
      axios.post('/api/weging', this.form)
          .catch((error) => {
            console.log("error" + error)
          });
      this.clearFields()
    },
    setgewicht: async function (event) {
      if (event) {
        let bascule = await axios.get('/api/bascule',)
        this.form.gewicht = bascule.data.gewicht;
        this.saveBtn = true;
      }
    },
    clearFields: function () {
      this.form = {
        hoknr: null,
        aantal: null,
        geslacht: null,
        controle: null,
        opmerking: null,
        gewicht: null
      }
      this.saveBtn = false;
    }

  }
}
</script>

<style scoped>

</style>